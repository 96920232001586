import config from '@/config';

import { PRODUCT_TYPE } from '@/config/common';
import { formatToTwoDecimalString } from '@/lib/numberStringUtils';
import { HOST_PORTAL_URL } from '@/services/connections.service';
import { COOKIE_MAP, getCookie } from '@/services/cookie.service';
import { getAgentInfo, getUserAuth } from '@/services/identity.service';

// TODO: Sidd - Cleanup - once the GA4 custom events are stable on production
const debugDataLayer = !config.isProduction;
function log(...arr) {
  // eslint-disable-next-line no-console
  debugDataLayer && console.log(...arr);
}
const recordAnalytics = Boolean(
  debugDataLayer ||
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENABLE?.toLowerCase() === 'true'
);

const unAvailableValuePlaceholder = '';
const CURRENCY = 'AED';

export const GTM_EVENTS = {
  ADD_DEFAULT_CART_SHIPPING_INFO: 'add_shipping_info',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_DEFAULT_CART_CHECKOUT: 'begin_checkout',
  BROWSE_HOME_PAGE_CATEGORIES_CLICK: 'browse_categories_click',
  GENERIC_CTA_CLICK: 'cta_click',
  LEAD_RECEIVED: 'lead_received',
  LOGIN_EVENT: 'login_event',
  LOGOUT_EVENT: 'logout_event',
  NAV_BAR_CLICK: 'top_navigation_clicks',
  PAGE_VIEW: 'page_view_custom',
  PDP_VISIT: 'view_item',
  PROCEED_TO_PAYMENT_CLICK: 'add_payment_info',
  PRODUCT_CARD_ON_PLP_CLICK: 'select_item',
  PRODUCT_SEARCH_HIT_ENTER: 'user_search',
  REGISTER_PURCHASE_COMPLETION: 'purchase',
  REMOVE_FROM_CART: 'remove_from_cart',
  WHATSAPP_CLICK: 'whatsapp_clicked'
};

export const GTM_EVENTS_CTA_NAME = {
  BECOME_PARTNER: 'become_partner',
  BLOG_VIEW_ALL: 'blog_view_all',
  BOOK_FREE_CONSULTATION: 'book_free_consultation',
  CONSULT_EVENT_EXPERT: 'consult_event_expert',
  CONTACT_US: 'contact_us',
  GALLERY_VIEW_ALL: 'gallery_view_all',
  MEGA_MENU: 'top_navigation',
  PHONE_CALL: 'phone_call',
  PLAN_YOUR_EVENT: 'plan_your_event',
  WHATSAPP: 'whatsapp'
};

export const PAGE_NAME = {
  PDP: { label: 'PDP', regex: /^\/products\/./ },
  PLANNER_CART: { label: 'PLANNER_CART', regex: /^\/planner-carts\/./ },
  MY_EVENTS: { label: 'MY_EVENTS', regex: /^\/my-events\/./ },
  QUOTE: { label: 'QUOTE', regex: /^\/quotes\/./ },
  HOST_CART: { label: 'HOST_CART', regex: /^\/host-carts\/./ },
  CLP_AND_PLP: { label: 'CLP_AND_PLP', regex: /^\/categories\/./ },
  COLLECTION_LIST: { label: 'COLLECTION_LIST', regex: /^\/collections/ },
  COLLECTION_HOME: {
    label: 'COLLECTION_HOME',
    regex: /^\/collections\/./
  },
  HOME_PAGE: { label: 'HOME_PAGE', regex: /^\/$/ },
  EVENT_MARKETING: { label: 'EVENT_MARKETING', regex: /^\/events\/./ },
  EVENT_LEAD_GEN: {
    label: 'EVENT_LEAD_GEN',
    regex: /^\/events\/lead-capture-form\/./
  },
  REGISTER_AS_PARTNER: {
    label: 'REGISTER_AS_PARTNER',
    regex: /^\/partners\/(basic-info|lead-capture-form|business-location-info)/g
  },
  CONTACT_US: { label: 'CONTACT_US', regex: /^\/contact-us/ },
  TERMS_AND_CONDITION: {
    label: 'TERMS_AND_CONDITION',
    regex: /^\/terms-and-conditions/
  },
  PRIVACY_POLICY: { label: 'PRIVACY_POLICY', regex: /^\/privacy-policy/ },
  ACCEPTABLE_USE_POLICY: {
    label: 'ACCEPTABLE_USE_POLICY',
    regex: /^\/acceptable-use-policy/
  },
  PRODUCT_SEARCH_RESULT_LIST: {
    label: 'PRODUCT_SEARCH_RESULT_LIST',
    regex: /^\/search-result\/./
  }
};

const getGoogleClientId = () => {
  const gaCookie = getCookie({ name: COOKIE_MAP.GA }) || '';
  return gaCookie.substring(6);
};

const getAllUserIds = () => {
  const userAuth = getUserAuth();
  const opsUserInfo = getAgentInfo();
  const plannerId = opsUserInfo?.agent?.id;
  let hostId;
  let guestId;
  if (plannerId) {
    hostId = opsUserInfo.id;
  } else {
    hostId = userAuth?.isGuest ? null : userAuth?.id; // TODO: Sidd - check if some common identity function can be used here
    guestId = userAuth?.isGuest ? userAuth.id : null; // TODO: Sidd - check if some common identity function can be used here
  }
  return {
    client_id: getGoogleClientId(),
    user_id: hostId,
    planner_id: plannerId,
    guest_id: guestId
  };
};

const captureEvent = (eventObject = {}) => {
  const eventObjectWithIds = {
    ...getAllUserIds(),
    ...eventObject
  };
  if (typeof window !== 'undefined' && recordAnalytics) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventObjectWithIds);
  }
};

const getPageName = (urlPathQueryFragment = '') => {
  const pageNameObjectList = Object.values(PAGE_NAME);
  const urlPath = new URL(`${HOST_PORTAL_URL}${urlPathQueryFragment}`).pathname;
  const pageNameObject = pageNameObjectList.find(({ regex }) =>
    regex.test(urlPath)
  );
  return pageNameObject?.label || 'unknown';
};

export const captureGTMEventPageView = (urlPathQueryFragment) => {
  log('captureGTMEventPageView', {
    event: GTM_EVENTS.PAGE_VIEW,
    page: urlPathQueryFragment,
    page_name: getPageName(urlPathQueryFragment)
  });
  captureEvent({
    event: GTM_EVENTS.PAGE_VIEW,
    page: urlPathQueryFragment,
    page_name: getPageName(urlPathQueryFragment)
  });
};

export const captureGTMEventCTAClick = ({ ctaName, pageName, path }) => {
  captureEvent({
    event: GTM_EVENTS.GENERIC_CTA_CLICK,
    cta_click: ctaName,
    page_name: pageName || getPageName(path)
  });
};

const cartAndOrderItemDefaultValues = {
  affiliation: 'Website',
  coupon: unAvailableValuePlaceholder,
  discount: 0,
  index: 0,
  item_brand: unAvailableValuePlaceholder,
  item_id: 'item-id-not-available',
  item_list_id: unAvailableValuePlaceholder,
  item_name: 'item-name-not-available',
  item_variant: unAvailableValuePlaceholder,
  location_id: unAvailableValuePlaceholder,
  price: 0,
  quantity: 1
};

const assignedToCategoriesWrapper = (assignedToCategories) => {
  if (!assignedToCategories || assignedToCategories.length === 0) return {};
  return {
    item_category: assignedToCategories[0]
      ? assignedToCategories[0].toLowerCase()
      : null,
    item_category2: assignedToCategories[1]
      ? assignedToCategories[1].toLowerCase()
      : null,
    item_category3: assignedToCategories[2]
      ? assignedToCategories[2].toLowerCase()
      : null,
    item_list_name: assignedToCategories[2]
      ? assignedToCategories[2].toLowerCase()
      : null
  };
};

const categoryWrapper = (categories) => {
  if (!categories) return {};
  const category = categories[0];
  return {
    item_category: category?.name.toLowerCase() || null,
    item_category2: category.parentCategory?.name.toLowerCase() || null,
    item_category3:
      category.parentCategory.parentCategory?.name.toLowerCase() || null,
    item_list_name:
      category.parentCategory.parentCategory?.name.toLowerCase() || null
  };
};

const checkoutAndPurchasedOrderItems = (orderItems) =>
  orderItems.map(
    (
      {
        categories,
        unitListedDiscount,
        quantity,
        entityId,
        product,
        sellingPrice
      },
      index
    ) => ({
      ...cartAndOrderItemDefaultValues,
      ...categoryWrapper(categories),
      discount: unitListedDiscount
        ? formatToTwoDecimalString({
          value: (unitListedDiscount || 0) * quantity
        })
        : 0,
      index,
      item_id: entityId,
      item_name: product.name,
      price: formatToTwoDecimalString({ value: sellingPrice }),
      quantity
    })
  );

const checkoutAndPurchasedCartItems = (cartItems) =>
  cartItems
    .filter(({ derivedValues: { isValidForCheckout } }) => isValidForCheckout)
    .map(
      (
        {
          id,
          derivedValues: { unitSellingPrice },
          product: { categories, name },
          quantity,
          unitListedDiscount
        },
        index
      ) => ({
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        discount: unitListedDiscount
          ? formatToTwoDecimalString({
            value: (unitListedDiscount || 0) * quantity
          })
          : 0,
        item_id: id,
        index,
        item_name: name,
        price: formatToTwoDecimalString({ value: unitSellingPrice || 0 }),
        quantity
      })
    );

export const captureGTMEventPurchaseCompletion = ({
  orderId,
  orderItems = [],
  orderTotal,
  paymentFlowSource
}) => {
  const eCommerce = {
    currency: CURRENCY,
    items:
      orderItems.length > 0
        ? checkoutAndPurchasedOrderItems(orderItems)
        : [{ ...cartAndOrderItemDefaultValues, price: orderTotal }],
    transaction_id: orderId,
    value: formatToTwoDecimalString({ value: orderTotal })
  };
  captureEvent({
    event: GTM_EVENTS.REGISTER_PURCHASE_COMPLETION,
    ecommerce: eCommerce,
    payment_flow_source: paymentFlowSource
  });
};

export const captureGTMEventBrowseCategoriesClick = ({
  name: categoryName
}) => {
  captureEvent({
    category: categoryName,
    event: GTM_EVENTS.BROWSE_HOME_PAGE_CATEGORIES_CLICK,
    page_name: PAGE_NAME.HOME_PAGE.label
  });
};

export const captureGTMEventSelectItem = ({
  assignedToCategories,
  availableDiscount,
  id,
  name,
  price,
  quantity,
  type
}) => {
  const productPrice = {
    Cart: formatToTwoDecimalString({ value: price }),
    Quote: 0
  };
  const ecommerce = {
    items: [
      {
        ...cartAndOrderItemDefaultValues,
        ...assignedToCategoriesWrapper(assignedToCategories),
        discount: availableDiscount?.value
          ? `${formatToTwoDecimalString({ value: availableDiscount.value })}%`
          : 0,
        index: 0,
        item_id: id,
        item_name: name,
        price: productPrice[type],
        quantity
      }
    ]
  };
  captureEvent({ event: GTM_EVENTS.PRODUCT_CARD_ON_PLP_CLICK, ecommerce });
};

export const captureGTMEventViewItem = ({
  availableDiscount,
  categories,
  id,
  name,
  price,
  type
}) => {
  const productPrice = {
    Cart: formatToTwoDecimalString({ value: price }),
    Quote: 0
  };

  const ecommerce = {
    currency: CURRENCY,
    value: productPrice[type],
    items: [
      {
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        discount: availableDiscount?.value
          ? `${formatToTwoDecimalString({ value: availableDiscount.value })}%`
          : 0,
        index: 0,
        item_id: id,
        item_name: name,
        price: productPrice[type]
      }
    ]
  };
  captureEvent({ event: GTM_EVENTS.PDP_VISIT, ecommerce });
};

export const captureGTMEventAddToCart = ({
  availableDiscount,
  assignedToCategories,
  categories,
  id,
  name,
  pageName,
  price,
  quantity,
  type = PRODUCT_TYPE.CART
}) => {
  const productPrice = {
    Cart: formatToTwoDecimalString({ value: price }),
    Quote: 0
  };

  const ecommerce = {
    currency: CURRENCY,
    value: productPrice[type],
    items: [
      {
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        ...assignedToCategoriesWrapper(assignedToCategories),
        coupon: unAvailableValuePlaceholder,
        discount: availableDiscount?.value
          ? `${formatToTwoDecimalString({ value: availableDiscount.value })}%`
          : 0,
        index: 0,
        item_id: id,
        item_name: name,
        price: productPrice[type],
        quantity
      }
    ]
  };
  captureEvent({
    event: GTM_EVENTS.ADD_TO_CART,
    ecommerce,
    page_name: pageName
  });
};

export const captureGTMEventRemoveFromCart = ({
  availableDiscount,
  categories,
  id,
  name,
  pageName,
  price,
  quantity,
  type
}) => {
  const productPrice = {
    Cart: formatToTwoDecimalString({ value: price }),
    Quote: 0
  };

  const ecommerce = {
    currency: CURRENCY,
    value: productPrice[type],
    items: [
      {
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        discount: availableDiscount?.value
          ? `${formatToTwoDecimalString({ value: availableDiscount.value })}%`
          : 0,
        index: 0,
        item_id: id,
        item_name: name,
        price: productPrice[type],
        quantity
      }
    ]
  };

  captureEvent({
    event: GTM_EVENTS.REMOVE_FROM_CART,
    ecommerce,
    page_name: pageName
  });
};

export const captureGTMEventSuccessfullLeadForm = () => {
  captureEvent({ event: GTM_EVENTS.LEAD_RECEIVED });
};

export const captureGTMEventWhatsappClick = () => {
  captureEvent({ event: GTM_EVENTS.WHATSAPP_CLICK });
};

export const captureGTMEventPhoneCall = () => {
  captureEvent({ event: GTM_EVENTS.PHONE_CALL });
};

export const captureGTMEventLogin = ({
  status,
  userId: userIdToOverrideAsNotYetSetInCookies
}) => {
  captureEvent({
    event: GTM_EVENTS.LOGIN_EVENT,
    status,
    user_id: userIdToOverrideAsNotYetSetInCookies
  });
};

export const captureGTMEventLogOut = () => {
  captureEvent({ event: GTM_EVENTS.LOGOUT_EVENT, status: true });
};

export const captureGTMEventNavBarClick = ({
  category: { name },
  childCategory,
  path
}) => {
  captureEvent({
    category: name,
    event: GTM_EVENTS.NAV_BAR_CLICK,
    page_name: getPageName(path),
    sub_category: childCategory
  });
};

export const captureGTMEventBeginCheckout = ({
  code = unAvailableValuePlaceholder,
  itemsList,
  orderTotal,
  pageName
}) => {
  const ecommerce = {
    currency: CURRENCY,
    value: formatToTwoDecimalString({ value: orderTotal }),
    coupon: code || unAvailableValuePlaceholder,
    items: checkoutAndPurchasedCartItems(itemsList)
  };
  captureEvent({
    event: GTM_EVENTS.BEGIN_DEFAULT_CART_CHECKOUT,
    pageName,
    ecommerce
  });
};

export const captureGTMEventProceedToPayment = ({
  cartItems,
  code,
  orderTotal,
  paymentType
}) => {
  const ecommerce = {
    coupon: code || unAvailableValuePlaceholder,
    currency: CURRENCY,
    items: checkoutAndPurchasedCartItems(cartItems),
    payment_type: paymentType,
    shipping_tier: 'Ground',
    value: formatToTwoDecimalString({ value: orderTotal })
  };

  captureEvent({ event: GTM_EVENTS.PROCEED_TO_PAYMENT_CLICK, ecommerce });
};

export const captureGTMEventAddShippingInfo = ({
  code = unAvailableValuePlaceholder,
  itemList,
  orderTotal
}) => {
  const ecommerce = {
    currency: CURRENCY,
    value: formatToTwoDecimalString({ value: orderTotal }),
    coupon: code || unAvailableValuePlaceholder,
    shipping_tier: 'Ground',
    items: checkoutAndPurchasedCartItems(itemList)
  };

  captureEvent({ event: GTM_EVENTS.ADD_DEFAULT_CART_SHIPPING_INFO, ecommerce });
};

export const updateGTMEventCartQuantity = ({
  availableDiscount,
  currentQuantity,
  id,
  minPrice,
  name,
  oldQuantity,
  pageName,
  price,
  type
}) => {
  const isQuantityChanged = oldQuantity !== currentQuantity;

  if (isQuantityChanged) {
    const isQuantityIncreased = oldQuantity < currentQuantity;
    const captureGTMEventClick = isQuantityIncreased
      ? captureGTMEventAddToCart
      : captureGTMEventRemoveFromCart;

    captureGTMEventClick({
      availableDiscount,
      id,
      minPrice,
      name,
      pageName,
      price,
      quantity: currentQuantity,
      type
    });
  }
};
