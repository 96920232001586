import { mediaStoreBaseURL } from '@/config/common';
import { captureGTMEventAddToCart } from '@/lib/gtm';
import { getFormattedDate } from '@/lib/time';
import { HOST_PORTAL_URL } from '@/services/connections.service';
import { getCartDetailById } from '@/services/hostCart.service';
import * as HttpService from '@/services/http.service';
import {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_BREADCRUMBS,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_KIDS_BDAY
} from '@/services/url.service';

export const getProducts = ({
  skip = 0,
  limit = 10,
  parentCategory = '',
  category = '',
  subCategory = '',
  sortOrder = ''
}) =>
  HttpService.getWithoutAuth(
    GET_PRODUCTS(skip, limit, parentCategory, category, subCategory, sortOrder)
  );

export const getProduct = (slug) =>
  HttpService.getWithoutAuth(GET_PRODUCT(slug));

export const getProductBreadCrumbs = (slug) =>
  HttpService.getWithoutAuth(GET_PRODUCT_BREADCRUMBS(slug));

export const getProductById = (productId) =>
  HttpService.getWithoutAuth(GET_PRODUCT_BY_ID(productId));

export const getProductsKidsBday = (data) =>
  HttpService.postWithoutAuth(GET_PRODUCT_KIDS_BDAY, data);

export const getBreadCrumbs = (productBreadCrumbs, product) => {
  let breadCrumbs = [
    {
      name: 'Home',
      link: '/'
    },
    {
      name: productBreadCrumbs.parentCategoryName,
      link: `/categories/${productBreadCrumbs.parentCategorySlug}`
    },
    {
      name: productBreadCrumbs.categoryName,
      link: `/categories/${productBreadCrumbs.parentCategorySlug}/${productBreadCrumbs.categorySlug}`
    },
    {
      name: productBreadCrumbs.subCategoryName,
      link: `/categories/${productBreadCrumbs.parentCategorySlug}/${productBreadCrumbs.categorySlug}/${productBreadCrumbs.subCategorySlug}`
    },
    {
      name: product.name,
      link: `/products/${product.slug}`
    }
  ];
  if (productBreadCrumbs && !productBreadCrumbs.parentCategoryName) {
    breadCrumbs = [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: productBreadCrumbs.categoryName,
        link: `/categories/${productBreadCrumbs.categorySlug}`
      },
      {
        name: productBreadCrumbs.subCategoryName,
        link: `/categories/${productBreadCrumbs.categorySlug}/${productBreadCrumbs.subCategorySlug}`
      },
      {
        name: product.name,
        link: `/products/${product.slug}`
      }
    ];
  }
  return breadCrumbs;
};

const getImageURLsPrefixWithBaseUrl = (list = []) =>
  list.map((url) => `${mediaStoreBaseURL}/${url}`);

const getImageListForGoogleProductSchemaDetails = ({ productMedia } = {}) => {
  const defaultProductImageUrl = 'default-images/default-product.png';
  const productHasImages = productMedia?.length > 0;

  let urlList = [defaultProductImageUrl];

  if (productHasImages) {
    urlList = productMedia.map(({ media }) => media.url).filter((n) => n);
  }

  return getImageURLsPrefixWithBaseUrl(urlList);
};

export const getGoogleProductSchemaDetails = (product, urlPath) => {
  const DEFAULT_DISCOUNT_END_DATE = '2050-12-30';

  const imageList = getImageListForGoogleProductSchemaDetails(product);

  const getDiscountedPrice = (price, { type, value = 0 }) => {
    const discountValue =
      type === 'Percentage' ? (price * (value / 100)) / 100 : value;
    return price === 0 ? 0 : price - discountValue;
  };

  const absolutePrice = parseFloat(product.price?.price ?? 0) / 100;
  const minPrice = parseFloat(product.price?.minPrice ?? 0) / 100;

  const discount = product.availableDiscount;
  const discountedPrice = discount
    ? getDiscountedPrice(absolutePrice, discount)
    : minPrice;

  const priceValidUntil = discount?.endDate
    ? getFormattedDate({ date: discount.endDate, format: 'YYYY-MM-DD' })
    : DEFAULT_DISCOUNT_END_DATE;

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: `${(Math.random() + 4).toFixed(1)}`,
      reviewCount: `${Math.round(Math.random() * 100) + 50}`
    },
    brand: {
      '@type': 'Brand',
      name: 'Hafla'
    },
    description: product.shortDescription ?? product.description,
    id: product.id,
    image: imageList,
    name: product.name,
    sku: product.slug,
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/UsedCondition',
      price: discountedPrice,
      priceCurrency: 'AED',
      priceValidUntil,
      url: `${HOST_PORTAL_URL}${urlPath}`
    }
  };
};

export const handleAddToCart = async ({
  createCart,
  isQuantityInvalid,
  pageName,
  product,
  quantity,
  revertQuantityToMin,
  setShowAddedToCartAlert,
  updateCartInPortalHeader
}) => {
  const {
    availableDiscount,
    categories,
    id,
    name,
    price: { price, minPrice },
    type
  } = product;

  if (!isQuantityInvalid) {
    captureGTMEventAddToCart({
      availableDiscount,
      categories,
      id,
      minPrice,
      name,
      pageName,
      price,
      quantity,
      type
    });

    const isCartCreated = await createCart({
      product,
      quantity,
      revertQuantityToMin
    });

    if (isCartCreated) {
      const { entity } = await getCartDetailById({
        cartId: isCartCreated.cartId
      });

      updateCartInPortalHeader && updateCartInPortalHeader(entity);
      setShowAddedToCartAlert(true);
      setTimeout(() => {
        setShowAddedToCartAlert(false);
      }, 5000);
    }
  }
};

export const ticketTypeMapping = {
  adult: [
    { type: 'Pricing_Adult_Minimumspend', displayText: 'Minimum spend' },
    { type: 'Pricing_Adult_Foodonly', displayText: 'Food only' },
    { type: 'Pricing_Adult_Softdrinks', displayText: 'Soft drink' },
    { type: 'Pricing_Adult_Sparkling', displayText: 'Sparkling' },
    { type: 'Pricing_Adult_Bubbly', displayText: 'Bubbly' }
  ],
  kids: [
    { type: 'Pricing_Olderkids', displayText: 'Older kids ' },
    { type: 'Pricing_Kids', displayText: 'Kids ' }
  ],
  couple: [{ type: 'Pricing_Couple', displayText: 'Couple' }]
};

export const increaseQuantity = ({
  quantity,
  maxBookable,
  setQuantity,
  setShowQuantityRangeAlert
}) => {
  if (quantity >= maxBookable) {
    setShowQuantityRangeAlert(true);
  }
  setQuantity((prevQuantity) => prevQuantity + 1);
};

export const decreaseQuantity = ({
  quantity,
  minBookable,
  maxBookable,
  setQuantity,
  setShowQuantityRangeAlert
}) => {
  setShowQuantityRangeAlert(
    Boolean(quantity <= minBookable || quantity - 1 > maxBookable)
  );
  setQuantity((prevQuantity) => prevQuantity - 1);
};
