import _ from 'lodash';

import { getUserEventIdFromSelectedCartCookie } from '@/services/cart.service';
import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from '@/services/cookie.service';
import * as HttpService from '@/services/http.service';
import {
  GET_CARTS_BY_EVENT_ID,
  GET_USER_EVENT_DETAILS_BY_ID
} from '@/services/url.service';

const getCartsByEventId = ({ userCartId, userEventId }) =>
  HttpService.getWithAuth(GET_CARTS_BY_EVENT_ID({ userCartId, userEventId }));

export const getUserEventDetailsById = ({ userId, userEventId }) =>
  HttpService.getWithAuth(
    GET_USER_EVENT_DETAILS_BY_ID({ userId, userEventId })
  );

export const getSelectedUserEvent = () =>
  getCookie({ name: COOKIE_MAP.SELECTED_USER_EVENT });

export const setSelectedUserEvent = ({
  data: userEventId,
  resetPreviousCartSelectionForSameEvent = false
}) => {
  const currentSelectedCart = getCookie({ name: COOKIE_MAP.SELECTED_CART });
  const isCurrentSelectedCartDefaultCart = currentSelectedCart?.isDefault;
  const currentSelectedCartEventId =
    getUserEventIdFromSelectedCartCookie(currentSelectedCart);

  const noUserEventChange = currentSelectedCartEventId === userEventId;

  const eventCookie = setCookie({
    data: userEventId,
    name: COOKIE_MAP.SELECTED_USER_EVENT
  });

  if (noUserEventChange && resetPreviousCartSelectionForSameEvent) {
    return {
      cartCookie: removeCookie({ name: COOKIE_MAP.SELECTED_CART }),
      eventCookie
    };
  }

  if (noUserEventChange || isCurrentSelectedCartDefaultCart) {
    return {
      cartCookie: currentSelectedCart,
      eventCookie
    };
  }

  return {
    cartCookie: removeCookie({ name: COOKIE_MAP.SELECTED_CART }),
    eventCookie
  };
};

export const fetchCartListByEventId = async ({
  setCartCardList,
  userCartId,
  userEventId
}) => {
  const { status: getCartsByEventIdStatus, entity: cartList } =
    await getCartsByEventId({
      userCartId,
      userEventId
    });
  if (getCartsByEventIdStatus) {
    setCartCardList(cartList);
  }
};

export const fetchUserEventCartDetails = async ({
  getUserEventCartList,
  setCartCardList,
  setLoading,
  setUserEvent,
  userCartId,
  userEventId,
  userId
}) => {
  setLoading(true);
  const { status, entity: userEvent } = await getUserEventDetailsById({
    userEventId,
    userId
  });
  if (status || _.isEmpty(userEvent)) {
    setSelectedUserEvent({
      data: userEventId
    });
    await getUserEventCartList();
    setUserEvent(userEvent);
    await fetchCartListByEventId({ userCartId, userEventId, setCartCardList });
  }
  setLoading(false);
};

const DUMMY_EVENT_DETAILS = {
  eventContactEmail: 'haflacustomer',
  eventContactName: 'ZD_AUTO_CUST',
  eventDate: '31st Dec 2099',
  eventStartTime: '24:00',
  formattedAddress: 'Nook Coworking - Dubai - United Arab Emirates'
};

export const isEmptyOrDummyEventDetail = ({ key, value }) => {
  const dummyValue = _.get(DUMMY_EVENT_DETAILS, key, 'key-not-found');
  if (_.isNil(value)) {
    return true;
  }

  if (dummyValue === 'key-not-found') {
    return false;
  }

  if (_.isNumber(value)) {
    return dummyValue === value;
  }
  if (_.isString(value) && _.isString(dummyValue)) {
    if (value?.trim() === '') {
      return true;
    }

    if (dummyValue?.trim() !== '') {
      return value.includes(dummyValue);
    }

    return false;
  }

  if (!_.isBoolean(value) && _.isEmpty(value)) {
    return true;
  }

  return false;
};
